import { CircularProgress, Container } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import AppSnackbar from "../../../../snackbar/Snackbar";
import styles from "./UniqueCodeInput.module.css";
import { dataCentral } from "../../../../context/DatagenContext";
import Moralis from 'moralis-v1';
import airdropABI from "../../../../../contracts/AirDrop.json";

const UniqieCodeInput = ({ airdropContract, airdropSocketContract }) => {
  const { addTokenToMetamask } = useContext(dataCentral);
  const [loading, setLoading] = useState(false);
  const [openInfoSnackbar, setOpenInfoSnackbar] = useState(false);
  const [incomingMessage, setIncomingMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState("");


  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "code") {
      setCode(value);
    }
  };

  const handleConfirm = async (e) => {
    e.preventDefault();

    setLoading(true);
    const options = {
      abi: airdropABI,
      functionName: "getAirdrop",
      contractAddress: '0x6e594cC1D458756C40056F39fDFF0AC1AbAD4129',
      params: {
        code: code,
      },
    };

    Moralis.executeFunction(options)
      .then((data) => {
        setIncomingMessage(
          "please do not reload or leave page, these actions may lead to a failed transaction"
        );
        setOpenInfoSnackbar(true);
        data
          .wait()
          .then((data) => {
            const count = data.events.length
           
            const {value} = data.events[count-1].args
            setLoading(false);
            setIncomingMessage(
              `Congratulations!, you got ${value} DG`
            );
            setOpen(true);
            addTokenToMetamask();
          })
          .catch((error) => {
         
            setLoading(false);
            setErrorMessage("Sorry!, incorrect code, no dg for you");
            setIncomingMessage(``);
            setOpen(true);
          });
      })
      .catch((err) => {
       
          setLoading(false);
       
          if(err.code === 4001){
            setErrorMessage(err?.message);
          }else{
            if(err.data === undefined){
              setErrorMessage('oops, something went wrong, reload the page');
            }else{
              setErrorMessage(err?.data?.message);
            }
           
          }
          setIncomingMessage(``);
          setOpen(true);
      });
  };

  return (
    <div className={styles.unique_code_input}>
      <Container maxWidth="sm">
        <div className={styles.unique_code_input_content}>
          <form action="">
            <input
              name="code"
              onChange={handleChange}
              type="text"
              placeholder="Enter your 27 digit unique code"
            />
            <button onClick={handleConfirm}>
            Get airdrop
              {loading && (
                <div className={styles.progress}>
                <CircularProgress  />
              </div>
              )}
            </button>
          </form>
        </div>
      </Container>

      <AppSnackbar
        open={open}
        errorMessage={errorMessage}
        incomingMessage={incomingMessage}
        setOpen={setOpen}
      />
    </div>
  );
};

export default UniqieCodeInput;
