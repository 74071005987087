import { Container, Typography } from "@material-ui/core";
import React from "react";
import styles from "./Product.module.css";

const Product = () => {
  return (
    <div className={styles.product}>
      <Container maxWidth="md">
        <div className={styles.product_content}>
          <div className={styles.product_heading}>
            <Typography>Products</Typography>
          </div>
          <div className={styles.solving}>
            <div className={styles.solving_heading}>
              <Typography>What we are solving ?</Typography>
            </div>
            <div className={styles.solving_content}>
              <div className={`${styles.bottom_line} ${styles.solving_item}`}>
                <Typography variant="h6">
                  Widespread use of centralized cloud computing.
                </Typography>
                <Typography>Leads to:</Typography>
                <ul>
                  <li>censorship</li>
                  <li>expensiveness</li>
                  <li>opaqueness</li>
                </ul>
              </div>
              {/* <div className={styles.solving_item}>
                <Typography variant='h6'>
                  <span className={styles.number}>
                    <img src={number2} alt="" />
                  </span>
                  Potentially harming the success of Web3 projects
                </Typography>
              </div> */}

              <div className={styles.solving_item}>
                <Typography variant="h6">
                  A blockchain based computational stack
                </Typography>
                <Typography>Needs to achieve:</Typography>
                <ul>
                  <li> affordability</li>
                  <li> cybersecurity</li>
                  <li> Decentralization (both software and hardware)</li>
                  <li> efficiency</li>
                  <li> resiliency</li>
                  <li> speed</li>
                  <li> 0 trust</li>
                </ul>
              </div>

              {/* <Typography>
                <span className={styles.number}>
                  <img src={number4} alt="" />
                </span>
                Web 3.0 is revolutionizing just the “skin” of digital economy:
                While web 3.0 is revolutionizing some software application (like
                with DApps, DeFi or different decentralized providers), the
                web-based tools still heavily rely on Web 2.0 backbones:
                centralized cloud computing and centralized search engines
              </Typography> */}
            </div>
          </div>
          <div className={styles.creating}>
            <div className={styles.creating_heading}>
              <Typography>What we are creating ?</Typography>
            </div>
            {/* <div className={styles.creating_sub_heading}>
              <div className={styles.horizontal_typo}>
                <Typography>Datagen infrastructure</Typography>
              </div>
              <Typography>
                The Datagen infrastructure, a combination of Layer1 (Fast
                Blockchain) and Layer2 (Heavy Blockchain) substrate-based
                blockchains.
              </Typography>
            </div> */}
            <div className={styles.creating_content}>
              <div className={styles.creating_content_tab}>
                <div className={styles.horizontal_typo}>
                  <Typography>infrastructure</Typography>
                </div>
                <Typography>
                  The Datagen infrastructure, a combination of Layer1 (Fast
                  Blockchain) and Layer2 (Heavy Blockchain) substrate-based
                  blockchains for on-chain verification of off-chain computional
                  workload.
                </Typography>
              </div>
              <div className={styles.creating_content_tab}>
                <div className={styles.horizontal_typo}>
                  <Typography>consensus protocol</Typography>
                </div>
                <Typography>
                  A new customized consensus protocol, that can keep high
                  standards of security, efficiency and latency time.
                </Typography>
              </div>
              <div className={styles.creating_content_tab}>
                <div className={styles.horizontal_typo}>
                  <Typography>blockchains</Typography>
                </div>
                <Typography>
                  The Fast Blockchain is a standalone bc, with a very short
                  blocktime and will avoid congestion by having many
                  non-computational related processes managed by the Heavy
                  Blockchain.
                </Typography>
                <Typography>
                  The Fast Blockchain and the Heavy Blockchain are efficiently
                  “speaking” using a custom RPC.
                </Typography>
                <Typography>
                  The Heavy Blockchain is a very resilient L2 blockchain that
                  manages: block authoring, random doublechecks, staking and all
                  the other functions that can be off-loaded from the Fast
                  Blockchain, to keep it fast and efficient.
                </Typography>
              </div>
              <div className={styles.creating_content_tab}>
                <div className={styles.horizontal_typo}>
                  <Typography>other products</Typography>
                </div>
                <Typography>
                  Other products, indirectly related to the Datagen network
                  (like APIs or tools for search engines), will be created
                  later.{" "}
                </Typography>
                <Typography>
                  We are already creating a functional Fast and Heavy
                  blockchains in local{" "}
                  <a
                    rel="noreferrer"
                    href="https://github.com/Datagen-Project/Datagen-Substrate-Grant"
                    target="_blank"
                  >
                    POC
                  </a>
                </Typography>
              </div>
            </div>
          </div>
          <div className={styles.whitepaper}>
            <div className={styles.whitepaper_heading}>
              <Typography>Whitepaper</Typography>
            </div>
            <div className={styles.whitepaper_content}>
              <Typography>
                For further technical details on the products download “DataGen
                Project technical Whitepaper by B-Datagray Limited”
              </Typography>
              <div>
                <a
                  rel="noreferrer"
                  href="https://drive.google.com/file/d/1jhxCdgg7PVK0aQaZS6UHDgICLBvXM1r0/view?usp=share_link"
                  target="_blank"
                  download
                >
                  <button>Download</button>
                </a>
              </div>
            </div>
          </div>
          <div className={`${styles.certik_report} ${styles.whitepaper}`}>
            <div className={styles.whitepaper_heading}>
              <Typography>Certik Report</Typography>
            </div>
            <div className={styles.whitepaper_content}>
              <Typography>DataGen Smart Contracts CertiK Report</Typography>
              <div>
                <a
                  href="https://drive.google.com/file/d/14f8RjGGi1uXpkultYDGQyQ3rA-uqKtyC/view?usp=drive_link"
                  target="_blank"
                  download
                >
                  <button>Download</button>
                </a>
              </div>
            </div>
          </div>
          <div className={styles.roadmap}>
            <div className={styles.roadmap_heading}>
              <Typography>Roadmap</Typography>
            </div>
            <div className={styles.roadmap_content}>
              <div className={styles.stage}>
                <div className={styles.upper}></div>
                <div className={styles.icon}>
                  <Typography>Q1</Typography>
                </div>
                <div className={styles.stage_content}>
                  <div className={styles.stage_content_upper}>
                    <Typography>
                      December 2021 B-Datagray Limited incorporated in Dublin,
                      Ireland.
                    </Typography>
                  </div>
                  <div className={styles.stage_content_lower}>
                    {/* <Typography>April 2021 Website- Landing page. </Typography> */}
                  </div>
                </div>
              </div>
              <div className={styles.stage}>
                <div className={styles.upper}></div>
                <div className={styles.icon2}>
                  <Typography>Q2</Typography>
                </div>
                <div className={styles.stage_content}>
                  <div className={styles.stage_content_upper}>
                    <Typography>
                      March 2022 DataGen #DG TGE.
                      <br /> Token kept in private.
                    </Typography>
                  </div>
                  <div className={styles.stage_content_lower}>
                    <Typography>
                      November 2022 PoC of the consensus protocol.
                    </Typography>
                  </div>
                </div>
              </div>
              <div className={styles.stage}>
                <div className={styles.upper}></div>
                <div className={styles.icon3}>
                  <Typography>Q3</Typography>
                </div>

                <div className={styles.stage_content}>
                  <div className={styles.stage_content_upper}>
                    <Typography>
                      March 2024 Prototype completed. Datagen infrastructure in
                      local.
                    </Typography>
                  </div>
                  <div className={styles.stage_content_lower}>
                    <Typography>
                      September 2024 Beta Netowork. 30 PoS validators testing
                      the infra.
                    </Typography>
                  </div>
                </div>
              </div>
              <div className={styles.stage}>
                <div className={styles.upper}></div>
                <div className={styles.icon4}>
                  <Typography>Q4</Typography>
                </div>
                <div className={styles.stage_content}>
                  <div className={styles.stage_content_upper}>
                    <Typography>September 2024 Listing DataGen #DG.</Typography>
                  </div>
                  <div className={styles.stage_content_lower}>
                    <Typography>
                      March 2025 Mainnet Datagen Network. Expanding to AI
                      inference segment.
                    </Typography>
                  </div>
                </div>
              </div>
              <div className={styles.stage}>
                <div className={`${styles.upper} ${styles.upper_last}`}></div>
                <div className={styles.icon5}>
                  <Typography>Q5</Typography>
                </div>
                <div className={styles.stage_content}>
                  <div className={styles.stage_content_upper}>
                    <Typography>
                      2026 Fully Scaled and Decentralized Datagen
                      Infrastructure. $1B+ computing power processed by the
                      network.
                    </Typography>
                  </div>
                  <div className={styles.stage_content_lower}></div>
                </div>
              </div>
            </div>


            <div className={styles.roadmap_content_sm}>
              <div className={styles.stage_sm}>
                <div className={styles.upper_sm}></div>
                <div className={styles.icon_sm}>
                  <Typography>Q1</Typography>
                </div>
                <div className={styles.stage_content_sm}>
                  <div className={styles.stage_content_upper_sm}>
                    <Typography>
                      December 2021 B-Datagray Limited incorporated in Dublin,
                      Ireland.
                    </Typography>
                  </div>
                  <div className={styles.stage_content_lower_sm}>
                    {/* <Typography>April 2021 Website- Landingpage. </Typography> */}
                  </div>
                </div>
              </div>
              <div className={styles.stage_sm}>
                <div className={styles.upper_sm}></div>
                <div className={styles.icon_sm2}>
                  <Typography>Q2</Typography>
                </div>
                <div className={styles.stage_content_sm}>
                  <div className={styles.stage_content_upper_sm}>
                    <Typography>
                      {" "}
                      March 2022 DataGen #DG TGE. Token kept in private.
                    </Typography>
                  </div>
                  <div className={styles.stage_content_lower_sm}>
                    <Typography>
                      November 2022 PoC of the consensus protocol.
                    </Typography>
                  </div>
                </div>
              </div>
              <div className={styles.stage_sm}>
                <div className={styles.upper_sm}></div>
                <div className={styles.icon_sm3}>
                  <Typography>Q3</Typography>
                </div>
                <div className={styles.stage_content_sm}>
                  <div className={styles.stage_content_upper_sm}>
                    <Typography>
                      March 2024 Prototype completed. Datagen infrastructure in
                      local.  </Typography>
                  </div>
                  <div className={styles.stage_content_lower_sm}>
                    <Typography>
                    September 2024 Beta Netowork. 30 PoS validators testing
                      the infra.
                    </Typography>
                  </div>
                </div>
              </div>
              <div className={styles.stage_sm}>
                <div className={styles.upper_sm}></div>
                <div className={styles.icon_sm4}>
                  <Typography>Q4</Typography>
                </div>
                <div className={styles.stage_content_sm}>
                  <div className={styles.stage_content_upper_sm}>
                    <Typography>September 2024 Listing DataGen #DG.</Typography>
                  </div>
                  <div className={styles.stage_content_lower_sm}>
                    <Typography>
                      March 2025 Mainnet Datagen Network. Expanding to AI
                      inference segment.
                    </Typography>
                  </div>
                </div>
              </div>
              <div className={styles.stage_sm}>
                <div className={styles.upper_sm}></div>
                <div className={styles.icon_sm5}>
                  <Typography>Q5</Typography>
                </div>
                <div className={styles.stage_content_sm}>
                  <div className={styles.stage_content_upper_sm}>
                    <Typography>
                      2026 Fully Scaled and Decentralized Datagen
                      Infrastructure. $1B+ computing power processed by the
                      network.
                    </Typography>
                  </div>
                  <div className={styles.stage_content_lower_sm}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Product;
