import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import "mapbox-gl/dist/mapbox-gl.css";
import DatagenContext from "./components/context/DatagenContext";
import { BrowserRouter as Router } from "react-router-dom";
import { MoralisProvider } from "react-moralis";
import Moralis from "moralis";

// console.log = console.warn = console.error = () => {};

// console.error('Something bad happened.');

// const APP_ID = process.env.REACT_APP_APPLICATION_ID;
// const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL;

ReactDOM.render(
  <React.StrictMode>
    {/* <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}> */}
      <DatagenContext>
        <Router>
          <App />
        </Router>
      </DatagenContext>
    {/* </MoralisProvider> */}
  </React.StrictMode>,
  document.getElementById("root")
);
