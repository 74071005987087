import React, { Component } from "react";
import styles from "./Default.module.css";
import { Container } from "@material-ui/core";
import error_icon from "./../assets/default.svg";
import { Link } from "react-router-dom";
const Default = (props) => {
    
    
  return (
    <div className={styles.default_page}>
      <Container>
        <div className={styles.default_page_content}>
          <img src={error_icon} alt="" />
          <h3 className={styles.text_center}>
            the path <b>{props.location.pathname} </b>  does not exist on this website, we can help you get back <Link to='/' > home </Link>
          </h3>
        </div>
      </Container>
    </div>
  );
  }

export default Default;
