import grau from "./assets/grau.jpeg";
import phillipe from "./assets/phillipe.png";
import nicolas from './assets/phillipe.jpg'

export const advisors = [
  {
    image: grau,
    id: 1,
    name: "Ruben Grau PujoL",
    quote:
      "Advisor & investor in fintech & blockchain startups to unlock the next wave of entrepreneurship & unleash the power of capital for good",
      linkedInUrl: 'https://linkedin.com/in/rubengraupujol'
  },
  {
    image:phillipe,
    id: 2,
    name: "Philippe Ho",
    quote:
      "Leading a startup expansion from early stages to public listing. Blockchain investor and author on CoinMonks",
      linkedInUrl: 'https://linkedin.com/in/philippe-ho-0300b5a0'
    },
    {
      image: nicolas,
      id: 2,
      name: "Nicolas Decouttere",
      quote:
        "Entrepreneur & Advisor. Providing blockchain product development services to tech projects and financial institutions.",
        linkedInUrl: 'https://linkedin.com/in/nicolas-decouttere-520aa2a'
      },
];

