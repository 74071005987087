import React, { useContext, useState } from "react";
import styles from "./Airdrop.module.css";
import underlay from "./../../assets/illustration-elements_second-gradient.svg";
import mobile_underlay from "./../../assets/illustration-elements_mobile-home-gradient (1).svg";

import SalesFooter from "./../component/footer/SalesFooter";
import UniqieCodeInput from "./components/page2/UniqueCodeInput";
import SalesNavbar from "../component/navbar/Navbar";
import SalesPage1 from "../component/page1/SalesPage1";

const Airdrop = () => {
  const [openNextStep, setOpenNextStep] = useState();

  return (
    <div className={styles.airdrop}>
      <img className={styles.airdrop_underlay} src={underlay} alt="" />

      <img
        className={styles.airdrop_mobile_underlay}
        src={mobile_underlay}
        alt=""
      />

      <div className={styles.airdrop_content}>
        <SalesNavbar />
        {openNextStep ? (
          <UniqieCodeInput setOpenNextStep={setOpenNextStep} />
        ) : (
          <SalesPage1 setOpenNextStep={setOpenNextStep} />
        )}
        <SalesFooter />
      </div>
    </div>
  );
};

export default Airdrop;
