// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Default_default_page__2VJCb {\npadding-top: 3rem;\n    background: #ffffff;\n    padding-bottom: 5rem;\n}\n.Default_default_page_content__2TPyC {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n.Default_default_page__2VJCb img {\n    height: 400px;\n    width: 100%;\n}\n\n.Default_default_page__2VJCb h3{\ncolor: #1A2CCE;\ntext-align: center;\n}\n\n", "",{"version":3,"sources":["webpack://src/components/default/Default.module.css"],"names":[],"mappings":"AAAA;AACA,iBAAiB;IACb,mBAAmB;IACnB,oBAAoB;AACxB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;AACA,cAAc;AACd,kBAAkB;AAClB","sourcesContent":[".default_page {\npadding-top: 3rem;\n    background: #ffffff;\n    padding-bottom: 5rem;\n}\n.default_page_content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n.default_page img {\n    height: 400px;\n    width: 100%;\n}\n\n.default_page h3{\ncolor: #1A2CCE;\ntext-align: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"default_page": "Default_default_page__2VJCb",
	"default_page_content": "Default_default_page_content__2TPyC"
};
export default ___CSS_LOADER_EXPORT___;
