// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SalesFooter_sales_footer__wWSjc {\ndisplay: flex;\n\n}\n\n\n.SalesFooter_sales_footer_content__2MOty {\nwidth: 50%;\n}\n\n.SalesFooter_sales_footer_content__2MOty p {\n    font-family: Kanit;\nfont-size: 15px;\nfont-style: normal;\nfont-weight: 300;\nline-height: 20px;\nletter-spacing: 0.01em;\ntext-align: left;\ncolor: white;\n}\n\n\n@media (max-width: 720px) {\n    .SalesFooter_sales_footer_content__2MOty {\n        width: 100%;\n        }\n}", "",{"version":3,"sources":["webpack://src/components/sales/component/footer/SalesFooter.module.css"],"names":[],"mappings":"AAAA;AACA,aAAa;;AAEb;;;AAGA;AACA,UAAU;AACV;;AAEA;IACI,kBAAkB;AACtB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,iBAAiB;AACjB,sBAAsB;AACtB,gBAAgB;AAChB,YAAY;AACZ;;;AAGA;IACI;QACI,WAAW;QACX;AACR","sourcesContent":[".sales_footer {\ndisplay: flex;\n\n}\n\n\n.sales_footer_content {\nwidth: 50%;\n}\n\n.sales_footer_content p {\n    font-family: Kanit;\nfont-size: 15px;\nfont-style: normal;\nfont-weight: 300;\nline-height: 20px;\nletter-spacing: 0.01em;\ntext-align: left;\ncolor: white;\n}\n\n\n@media (max-width: 720px) {\n    .sales_footer_content {\n        width: 100%;\n        }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sales_footer": "SalesFooter_sales_footer__wWSjc",
	"sales_footer_content": "SalesFooter_sales_footer_content__2MOty"
};
export default ___CSS_LOADER_EXPORT___;
