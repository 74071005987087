import { Container } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import styles from "./Navbar.module.css";
import logo from "./../../../assets/cube_grad.svg";
import { Link } from "react-router-dom";
import { useMoralis, useChain } from "react-moralis";


const SalesNavbar = () => {
  const { isAuthenticated, account, authenticate, logout, isWeb3Enabled,enableWeb3, chainId } =
useMoralis();
  const [firstChar, setFirstChar] = useState("");
  const [lastChar, setlastChar] = useState("");
  const [chainName, setChainName] = useState("");

  useEffect(() => {
    removeSomeChar();
  }, [account]);

  useEffect(() => {
    determineChainName();
  }, [chainId]);

  const removeSomeChar = () => {
    if (account === null) {
    } else {
      setFirstChar(account.slice(0, 6));

      setlastChar(account.slice(-4));
    }
  };

  const determineChainName = () => {
    if (account === undefined) {
    } else {
      if (chainId === 80001) {
        setChainName("Mumbai");
      } else if (chainId === 1) {
        setChainName("Mainnet");
      } else if (chainId === 137) {
        setChainName("Polygon (Matic)");
      } else if (chainId === 3) {
        setChainName("Ropsten");
      } else if (chainId === 42) {
        setChainName("Kovan");
      } else if (chainId === 4) {
        setChainName("Rinkeby");
      } else if (chainId === 5) {
        setChainName("Goerli");
      } else if (chainId === 56) {
        setChainName("BSC");
      }else if(chainId === '0x61'){
        setChainName("BSC testnet");
      }else if(chainId === '0x38'){
        setChainName("BSC");
      } else {
        setChainName(chainId);
      }
    }
  };

  return (
    <div className={styles.navbar}>
      <Container>
        <div className={styles.navbar_content}>
          <Link to='/' >
          <img src={logo} alt="" />
          </Link>


          <div className={styles.wallet_info}>
            {account === undefined ? (
              <button>Not connected</button>
            ) : (
              <>
                <button>{chainName}</button>
                <button>{`${firstChar}...${lastChar}`}</button>
              </>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SalesNavbar;
