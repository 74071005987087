// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../about-us/assets/illustration-elements_second-gradient.svg";
import ___CSS_LOADER_URL_IMPORT_1___ from "../about-us/assets/illustration-elements_mobile-home-gradient (1).svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Home_home__3yqds{\n   \n   background-color: #1C294B;\n    width: 100%;\n    padding-top: 250px;\n    position: relative;\n}\n\n.Home_home__3yqds::after {\n  position: absolute;\n  height: 100%;\n  width: 100%;\n  content: \"\";\n  left: 0;\n  bottom: 0;\n  \n  background-size: contain;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 100% 0%\n    no-repeat;\n  \n}\n\n\n\n\n@media (max-width: 600px) {\n  .Home_home__3yqds{\n    padding-top: 200px;\n}\n.Home_home__3yqds::after {\n\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") 100% 0%\n    no-repeat;\n  \n}\n}\n\n@media (max-width: 400px) {\n  .Home_home__3yqds{\n    padding-top: 150px;\n}\n\n}", "",{"version":3,"sources":["webpack://src/components/home/Home.module.css"],"names":[],"mappings":"AAAA;;GAEG,yBAAyB;IACxB,WAAW;IACX,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,WAAW;EACX,OAAO;EACP,SAAS;;EAET,wBAAwB;EACxB;aACW;;AAEb;;;;;AAKA;EACE;IACE,kBAAkB;AACtB;AACA;;EAEE;aACW;;AAEb;AACA;;AAEA;EACE;IACE,kBAAkB;AACtB;;AAEA","sourcesContent":[".home{\n   \n   background-color: #1C294B;\n    width: 100%;\n    padding-top: 250px;\n    position: relative;\n}\n\n.home::after {\n  position: absolute;\n  height: 100%;\n  width: 100%;\n  content: \"\";\n  left: 0;\n  bottom: 0;\n  \n  background-size: contain;\n  background: url(\"./../about-us/assets/illustration-elements_second-gradient.svg\") 100% 0%\n    no-repeat;\n  \n}\n\n\n\n\n@media (max-width: 600px) {\n  .home{\n    padding-top: 200px;\n}\n.home::after {\n\n  background: url(\"./../about-us/assets/illustration-elements_mobile-home-gradient\\ \\(1\\).svg\") 100% 0%\n    no-repeat;\n  \n}\n}\n\n@media (max-width: 400px) {\n  .home{\n    padding-top: 150px;\n}\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home": "Home_home__3yqds"
};
export default ___CSS_LOADER_EXPORT___;
