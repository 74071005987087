import React, { useEffect, useState } from "react";
import tokenLogo from "./../assets/tokenLogo4.png";
export const dataCentral = React.createContext();

const DatagenContext = (props) => {
  const [activeLink, setActiveLink] = useState("");

  const addTokenToMetamask = async () => {
    const { ethereum } = window;
    const tokenAddress = process.env.REACT_APP_DG_ADDRESS; //dg contract address
    const tokenSymbol = "#DG";
    const tokenDecimals = 18;

    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            image: tokenLogo, // A string url of the token logo
          },
        },
      });

      if (wasAdded) {
      } else {
      }
    } catch (error) {}
  };

  return (
    <dataCentral.Provider
      value={{
        activeLink,
        setActiveLink,
        addTokenToMetamask,
      }}
    >
      {props.children}
    </dataCentral.Provider>
  );
};

export default DatagenContext;
