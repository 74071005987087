import React, { useState, useEffect, useContext } from "react";
import { Container, Typography } from "@material-ui/core";
import styles from "./AmountForm.module.css";
import usdc_icon from "./../../../assets/usdc.svg";
import tokenLogo from "./../../../assets/tokenLogo3.svg";
import { useLocation } from "react-router";
import AppSnackbar from "../../../snackbar/Snackbar";
import Loader from "react-loader-spinner";
import NumberFormat from "react-number-format";
import axios from "axios";
import InfoSnackbar from "../../../snackbar/InfoSnackbar";
import { dataCentral } from "../../../context/DatagenContext";
import { useMoralis, useMoralisSubscription } from "react-moralis";
import { ethers } from "ethers";
import retailAbi from "../../../../contracts/RetailPrivateSale.json";
import vcAbi from "../../../../contracts/VCPrivateSale.json";
import usdcAbi from "../../../../contracts/USDCtest.json";

import Moralis from "moralis";
const AmountForm = () => {
  const { account } = useMoralis();
  const { addTokenToMetamask } = useContext(dataCentral);
  const [open, setOpen] = useState(false);
  const [incomingMessage, setIncomingMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const [amount, setAmount] = useState("");
  const [code, setCode] = useState("");
  const [usdcBalInRetailSC, setUsdcBalInRetailSC] = useState("");
  const [amountRaisedDG, setAmountRaisedDG] = useState("");
  const [amountInUSDC, setAmountInUSDC] = useState(Number);
  const [accountBalDG, setAccountBalDG] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [dgRem, setdgRem] = useState("");
  const [error, setError] = useState(false);
  const [openInfoSnackbar, setOpenInfoSnackbar] = useState(false);

  useEffect(() => {
    handleGetDataFromSc();
  }, []);
  useEffect(() => {
    handleCalculateDG();
  }, [amount, amountRaisedDG]);

  useEffect(() => {
    calculateDGRemaining();
  }, [amountRaisedDG]);

  useEffect(() => {
    handleCheckLimit2();
  }, [amount]);

  useEffect(() => {
    if (accountBalDG != null) {
      setPageLoading(false);
    }
  }, [accountBalDG]);

  useMoralisSubscription("FundTransferRetail", (q) => q, [], {
    onUpdate: (data)=>{
      handleActionAfterFundTransfer(data.attributes);
    },  
  });
  useMoralisSubscription("fundTransferVc", (q) => q, [], {
    onUpdate: (data)=>{
      handleActionAfterFundTransferVC(data.attributes);
    },  
  });

  const handleActionAfterFundTransferVC = (data) => {
    if (location.pathname.includes("vc")) {
      setAmountRaisedDG(Moralis.Units.FromWei(data.amountRaisedDG) * 1);
    } else {
    }
  };

  const handleActionAfterFundTransfer = (data) => {
    if (location.pathname.includes("retail")) {
      setAmountRaisedDG(Moralis.Units.FromWei(data.amountRaisedDG) * 1);
    } else {
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (amount.length > 0) {
      setLoading(true);
      if (location.pathname.includes("retail")) {
        const options = {
          abi: usdcAbi,
          functionName: "approve",
          contractAddress: process.env.REACT_APP_USDC_CONTRACT_ADDRESS,
          params: {
            spender: process.env.REACT_APP_RETAIL_CONTRACT_ADDRESS,
            amount: ethers.utils.parseUnits(amountInUSDC),
          },
        };

        const options2 = {
          abi: retailAbi,
          functionName: "invest",
          contractAddress: process.env.REACT_APP_RETAIL_CONTRACT_ADDRESS,
          params: {
            amountDG: ethers.utils.parseUnits(amount),
          },
        };

        Moralis.executeFunction(options)
          .then((data) => {
            setIncomingMessage(
              "please do not reload or leave page, these actions may lead to a failed transaction"
            );
            setOpenInfoSnackbar(true);
            data.wait().then((data) => {
              if (data !== null) {
                Moralis.executeFunction(options2)
                  .then((data) => {
                    setIncomingMessage(
                      "please do not reload or leave page, these actions may lead to a failed transaction"
                    );
                    setOpenInfoSnackbar(true);
                    data.wait().then((data) => {
                      const count = data.events.length;
                      const { backer, amountUSDC, amountDG } =
                        data.events[count - 1].args;
                      setLoading(false);
                      setErrorMessage("");
                      setIncomingMessage("Success");
                      setOpen(true);
                      handleSaveDetails(
                        backer,
                        amountUSDC.toString(),
                        amountDG.toString(),
                        "retail"
                      );
                      if (accountBalDG == 0) {
                        addTokenToMetamask();
                      }
                      handleGetDataFromSc();
                    });
                  })
                  .catch((error) => {
                    setLoading(false);
                    setIncomingMessage("");

                    if (error.code === 4001) {
                      setErrorMessage(error.message);
                    } else {
                      setErrorMessage(error.data.message);
                    }
                    setOpen(true);
                  });
              }
            });
          })
          .catch((error) => {
            setLoading(false);
            setIncomingMessage("");
            console.log(error);
            if (error.code === 4001) {
              setErrorMessage(error.message);
            } else {
              //setErrorMessage(error.data.message);
            }
            setOpen(true);
          });
      } else {
        const options = {
          abi: usdcAbi,
          functionName: "approve",
          contractAddress: process.env.REACT_APP_USDC_CONTRACT_ADDRESS,
          params: {
            spender: process.env.REACT_APP_VC_CONTRACT_ADDRESS,
            amount: ethers.utils.parseUnits(amountInUSDC),
          },
        };

        const options2 = {
          abi: vcAbi,
          functionName: "invest",
          contractAddress: process.env.REACT_APP_VC_CONTRACT_ADDRESS,
          params: {
            amountDG: ethers.utils.parseUnits(amount),
          },
        };

        Moralis.executeFunction(options)
          .then((data) => {
            setIncomingMessage(
              "please do not reload or leave page, these actions may lead to a failed transaction"
            );
            setOpenInfoSnackbar(true);
            data.wait().then((data) => {
              if (data !== null) {
                Moralis.executeFunction(options2)
                  .then((data) => {
                    setIncomingMessage(
                      "please do not reload or leave page, these actions may lead to a failed transaction"
                    );
                    setOpenInfoSnackbar(true);
                    data.wait().then((data) => {
                      if (data !== null) {
                        const count = data.events.length;
                        const { backer, amountUSDC, amountDG } =
                          data.events[count - 1].args;
                        setLoading(false);
                        setErrorMessage("");
                        setIncomingMessage("Success");
                        setOpen(true);
                        handleSaveDetails(
                          backer,
                          amountUSDC.toString(),
                          amountDG.toString(),
                          "vc"
                        );
                        if (accountBalDG == 0) {
                          addTokenToMetamask();
                        }
                        handleGetDataFromSc();
                      }
                    });
                  })
                  .catch((error) => {
                    setLoading(false);
                    setIncomingMessage("");

                    if (error.code === 4001) {
                      setErrorMessage(error.message);
                    } else {
                      setErrorMessage(error.data.message);
                    }
                    setOpen(true);
                  });
              }
            });
          })
          .catch((error) => {
            setLoading(false);
            setIncomingMessage("");

            if (error.code === 4001) {
              setErrorMessage(error.message);
            } else {
              setErrorMessage(error.data.message);
            }
            setOpen(true);
          });
      }
    } else {
      setIncomingMessage("");
      setErrorMessage("please fill amount");
      setOpen(true);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "amount") {
      setAmount(value);
    } else if (name === "code") {
      setCode(value);
    }
  };

  const handleSaveDetails = async (addr, amountofusdc, amountofdg, type) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/api/saveInvestorDetails`,
        {
          address: addr,
          tokens: amountofdg,
          referral: code,
          usdc: amountofusdc,
          type: type,
        },
        {
          params: {
            type: type,
          },
        }
      );
    } catch (error) {}
  };

  const handleCheckLimit2 = () => {
    if (accountBalDG != null) {
      if (location.pathname.includes("retail")) {
        if (amount * 1 < 10) {
          setIncomingMessage("");
          setErrorMessage("the lowest #DG to purchase is 10DG");
          setOpen(true);
          setError(true);
        } else if (amount * 1 + accountBalDG > 10000) {
          setIncomingMessage("");
          setErrorMessage(
            "you have exceeded the maximum limit of #DG to purchase, contact founders"
          );
          setError(true);
          setOpen(true);
        } else if (amount * 1 > dgRem) {
          setIncomingMessage("");
          setErrorMessage(
            `you are exceeding the available #DG amount, which is ${dgRem}`
          );
          setOpen(true);
          setError(true);
        } else {
          setError(false);
          setOpen(false);
          setErrorMessage("");
        }
      } else {
        if (amount * 1 < 20000) {
          setError(true);
          setIncomingMessage("");
          setErrorMessage("the lowest #DG to purchase in VC is 20000DG");
          setOpen(true);
        } else if (amount * 1 + accountBalDG > 2350000) {
          setIncomingMessage("");
          setErrorMessage("the highest #DG to purchase in VC is 2,350,000DG");
          setOpen(true);
          setError(true);
        } else if (amount * 1 > dgRem) {
          setIncomingMessage("");
          setErrorMessage(
            `you are exceeding the available #DG amount, which is ${dgRem}`
          );
          setOpen(true);
          setError(true);
        } else {
          setOpen(false);
          setErrorMessage("");
          setError(false);
        }
      }
    }
  };

  const handleCalculateDG = () => {
    if (location.pathname.includes("retail")) {
      if (amountRaisedDG + amount * 1 <= 15000) {
        setAmountInUSDC((amount * 0.7).toFixed(2));
      } else if (amountRaisedDG > 15000) {
        setAmountInUSDC((amount * 1).toFixed(2));
      } else {
        const fullPrice = amountRaisedDG + amount * 1 - 15000;
        const discountPrice = amount * 1 - fullPrice;

        const discountprice2 = discountPrice * 0.7;
        const fullPrice2 = fullPrice;

        setAmountInUSDC((discountprice2 + fullPrice2).toFixed(2));
      }
    } else {
      if (amountRaisedDG + amount * 1 <= 300000) {
        setAmountInUSDC((amount * 0.7).toFixed(2));
      } else if (amountRaisedDG >= 1300000) {
        setAmountInUSDC((amount * 1.1).toFixed(2));
      } else if (
        amountRaisedDG + amount * 1 <= 1300000 &&
        amountRaisedDG >= 300000
      ) {
        setAmountInUSDC((amount * 0.9).toFixed(2));
      } else if (
        amountRaisedDG <= 300000 &&
        amountRaisedDG + amount * 1 <= 1300000
      ) {
        const amountSecondPrice = amountRaisedDG + amount * 1 - 300000;
        const amountFirstPrice = amount * 1 - amountSecondPrice;

        setAmountInUSDC(
          (amountFirstPrice * 0.7 + amountSecondPrice * 0.9).toFixed(2)
        );
      } else if (amountRaisedDG >= 300000 && amountRaisedDG <= 1300000) {
        const amountThirdPrice = amountRaisedDG + amount * 1 - 1300000;
        const amountSecondPrice = amount * 1 - amountThirdPrice;

        setAmountInUSDC(
          (amountSecondPrice * 0.9 + amountThirdPrice * 1.1).toFixed(2)
        );
      } else if (amountRaisedDG <= 300000 && amount * 1 >= 1300000) {
        const amountThirdPrice = amountRaisedDG + (amount * 1) - 1300000;
        console.log(amountThirdPrice)
        const amountSecondPrice = 1000000;
        
        const amountFirstPrice =
          (amount * 1) - amountThirdPrice - amountSecondPrice;
        
          setAmountInUSDC(
          (
            (amountFirstPrice * 0.7)+
            (amountSecondPrice * 0.9)+
           ( amountThirdPrice * 1.1)
          ).toFixed(2)
        );
      }
    }
  };

  const handleGetDataFromSc = async () => {
    try {
      if (location.pathname.includes("retail")) {
        const options = {
          abi: retailAbi,
          contractAddress: process.env.REACT_APP_RETAIL_CONTRACT_ADDRESS,
          params: {
            addr: account,
          },
        };

        const funds = await Moralis.executeFunction({
          functionName: "checkFunds",
          ...options,
        });

        setUsdcBalInRetailSC(Moralis.Units.FromWei(funds) * 1);

        const amountRaisedInDGRetail = await Moralis.executeFunction({
          functionName: "amountRaisedDG",
          ...options,
        });

        setAmountRaisedDG(Moralis.Units.FromWei(amountRaisedInDGRetail) * 1);

        const dgBal = await Moralis.executeFunction({
          functionName: "checkDataGenFunds",
          ...options,
        });

        setAccountBalDG(Moralis.Units.FromWei(dgBal) * 1);
      } else {
        const options = {
          abi: vcAbi,
          contractAddress: process.env.REACT_APP_VC_CONTRACT_ADDRESS,
          params: {
            addr: account,
          },
        };

        const funds = await Moralis.executeFunction({
          functionName: "checkFunds",
          ...options,
        });
        setUsdcBalInRetailSC(Moralis.Units.FromWei(funds) * 1);

        const amountRaisedInDGRetail = await Moralis.executeFunction({
          functionName: "amountRaisedDG",
          ...options,
        });

        setAmountRaisedDG(Moralis.Units.FromWei(amountRaisedInDGRetail) * 1);
        const dgBal = await Moralis.executeFunction({
          functionName: "checkDataGenFunds",
          ...options,
        });

        setAccountBalDG(Moralis.Units.FromWei(dgBal) * 1);
      }
    } catch (error) {}
  };

  const calculateDGRemaining = () => {
    if (location.pathname.includes("retail")) {
      const remDG = 150000 - amountRaisedDG;

      setdgRem(remDG);
    } else {
      const remDG = 2350000 - amountRaisedDG;
      setdgRem(remDG);
    }
  };

  const handleSnackbarClose = () => {
    setOpenInfoSnackbar(false);
  };

  return (
    <div className={styles.amount_form}>
      {accountBalDG === null ? null : (
        <Container maxWidth="lg">
          <div className={styles.show_account_bal}>
            <Typography>Invested Balance: </Typography>
            <Typography variant="h6">
              <NumberFormat
                value={accountBalDG}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"#DG "}
              />
            </Typography>
          </div>
        </Container>
      )}

      <Container maxWidth="sm">
        <div className={styles.amount_form_content}>
          {pageLoading ? (
            <Loader type="Bars" color="#00BFFF" height={65} width={65} />
          ) : (
            <form action="">
              <div className={styles.amount_form_input_box}>
                <input
                  type="text"
                  name="amount"
                  onChange={handleChange}
                  value={amount}
                  placeholder={
                    location.pathname.includes("retail")
                      ? "50 #DG"
                      : "50,000 #DG"
                  }
                />
                <div>
                  <img src={tokenLogo} alt="" />
                </div>
              </div>
              <div className={styles.amount_form_text}>
                <Typography>you will pay:</Typography>
                <div className={styles.amount_form_amount}>
                  <NumberFormat
                    value={amountInUSDC}
                    displayType={"text"}
                    thousandSeparator={true}
                  />

                  <div>
                    <img src={usdc_icon} alt="" />
                  </div>
                </div>
              </div>
              <div className={styles.amount_form_referal_input}>
                <input
                  type="text"
                  placeholder="Your Referral Code (optional)"
                  name="code"
                  onChange={handleChange}
                  value={code}
                />
                <Typography>
                  Remaining #DG:{" "}
                  <NumberFormat
                    value={dgRem}
                    displayType={"text"}
                    thousandSeparator={true}
                  />{" "}
                </Typography>
              </div>
              <div className={styles.amount_form_btn}>
                <button disabled={error} onClick={handleSubmit}>
                  {" "}
                  {loading ? (
                    <Loader
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      type="Bars"
                      color="#00BFFF"
                      height={25}
                      width={25}
                    />
                  ) : (
                    "Confirm"
                  )}
                </button>
              </div>
            </form>
          )}
        </div>
      </Container>
      <AppSnackbar
        open={open}
        errorMessage={errorMessage}
        incomingMessage={incomingMessage}
        setOpen={setOpen}
      />
      <InfoSnackbar
        message={incomingMessage}
        handleClose={handleSnackbarClose}
        open={openInfoSnackbar}
      />
    </div>
  );
};

export default AmountForm;
