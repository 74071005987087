import React, { useState } from "react";
import styles from "./VcSales.module.css";
import underlay from "./../../assets/illustration-elements_second-gradient.svg";
import mobile_underlay from "./../../assets/illustration-elements_mobile-home-gradient (1).svg";
import SalesFooter from "../component/footer/SalesFooter";
import SalesNavbar from "../component/navbar/Navbar";
import SalesPage1 from "../component/page1/SalesPage1";
import AmountForm from "../component/usdcForm/AmountForm";

const VcSale = () => {
  const [openNextStep, setOpenNextStep] = useState();
 

  return (
    <div className={styles.vcsale}>
      <img className={styles.vcsale_underlay} src={underlay} alt="" />

      <img
        className={styles.vcsale_mobile_underlay}
        src={mobile_underlay}
        alt=""
      />
      <div className={styles.vcsale_content}>
        <SalesNavbar />
        {openNextStep ? (
          <AmountForm
          
            setOpenNextStep={setOpenNextStep}
          
          />
        ) : (
          <SalesPage1
          
            setOpenNextStep={setOpenNextStep}
          />
        )}
        <SalesFooter />
      </div>
    </div>
  );
};

export default VcSale;
