import { CircularProgress, Container, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styles from "./Airdrop1.module.css";
import tokenLogo from "./../../../assets/tokenLogo.png";
import metamask from "./../../../assets/metamask-fox.svg";
import walletConnectIcon from "./../../../assets/walletconnect-logo.svg";
import arrow from "./../../../assets/Vector 1.svg";
import useWindowSize from "../../../customHooks/WindowSize";
import AppSnackbar from "../../../snackbar/Snackbar";
import { useMoralis } from "react-moralis";
import Moralis from "moralis-v1";

const SalesPage1 = ({ setOpenNextStep }) => {
  const {
    deactivateWeb3,
    isAuthenticated,
    authenticate,
    logout,
    isWeb3Enabled,
    enableWeb3,
    chainId,
  } = useMoralis();

  const [open, setOpen] = useState(false);
  const [incomingMessage, setIncomingMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const size = useWindowSize();

  useEffect(() => {
    if (isAuthenticated) {
      if (isWeb3Enabled) {
      } else {
        enableWeb3();
      }
    }
  }, [isAuthenticated]);

  const handleConnectMetaMask = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      if (size.width < 768) {
        setOpen(true);
        setErrorMessage("access platform from metamask browser");
      } else {
        setOpen(true);
        setErrorMessage("install metamask to proceed");
      }
      return;
    } else {
      handleAuth();
    }
  };

  const handleAuth = async () => {
    try {
      //setAuthError(null);
      setIsAuthenticating(true);

      // Enable web3 to get user address and chain
      await enableWeb3({ throwOnError: true, provider: "metamask" });
      const { account, chainId } = Moralis;

      if (!account) {
        throw new Error(
          "Connecting to chain failed, as no connected account was found"
        );
      }
      if (!chainId) {
        throw new Error(
          "Connecting to chain failed, as no connected chain was found"
        );
      }

      // Get message to sign from the auth api

      const { message } = await Moralis.Cloud.run("requestMessage", {
        address: account,
        chain: parseInt(chainId, 16),
        networkType: "evm",
      });

    
      // Authenticate and login via parse
      await authenticate({
        signingMessage: message,
        throwOnError: true,
      });
      //onClose();
    } catch (error) {
      console.log(error);
      //setAuthError(error);
    } finally {
      setIsAuthenticating(false);
    }
  };

  const handleDisconnectMetaMask = async () => {
    deactivateWeb3();
    try {
      logout();
      localStorage.setItem("metaconnected", JSON.stringify(false));
    } catch (ex) {}
  };

  const handleNextStep = () => {
    if (chainId === process.env.REACT_APP_CHAIN) {
      setOpenNextStep(true);
    } else {
      setOpen(true);
      setErrorMessage(
        "Please switch to the Binance Smartchain then reload page"
      );
    }
  };

  return (
    <div className={styles.airdrop1}>
      <Container maxWidth="md">
        <div className={styles.airdrop1_content}>
          <div className={styles.airdrop1_header}>
            <Typography>Connect to your MetaMask Wallet:</Typography>
          </div>
          <div className={styles.airdrop1_icons}>
            <div className={styles.icon_div}>
              <img src={tokenLogo} alt="" />
            </div>
            <div className={styles.arrow}>
              <img src={arrow} alt="" />
            </div>
            <div className={styles.icon_div}>
              <img
                src={size.width <= 0 ? walletConnectIcon : metamask}
                alt=""
              />
            </div>
          </div>
          <div className={styles.airdrop1_btn}>
            <button
              onClick={
                isAuthenticated
                  ? handleDisconnectMetaMask
                  : handleConnectMetaMask
              }
            >
              {" "}
              {isAuthenticated ? "disconnect wallet" : "Connect Metamask"}
              {isAuthenticating && (
                <div className={styles.progress}>
                  <CircularProgress />
                </div>
              )}
            </button>

            {isAuthenticated ? (
              <button onClick={handleNextStep}>Next</button>
            ) : null}
          </div>
        </div>
      </Container>
      <AppSnackbar
        open={open}
        errorMessage={errorMessage}
        incomingMessage={incomingMessage}
        setOpen={setOpen}
      />
    </div>
  );
};

export default SalesPage1;
