import React, { useEffect, useContext, useState } from "react";
import { CircularProgress, Container, Typography } from "@material-ui/core";
import styles from "./Footer.module.css";
import logo from "./../navbar/assets/cube_grad.svg";
import { Link } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";
import discord_icon from "./../contact/assets/illustration-elements_icon-discord.svg";
import linkedin_icon from "./../contact/assets/illustration-elements_icon-linkedn.svg";
import telegram_icon from "./../contact/assets/illustration-elements_icon-telegram.svg";
import github_icon from "./../contact/assets/illustration-elements_icon-github_02.svg";
import twitter_icon from "./../contact/assets/TWITTER.svg";
import axios from "axios";
import AppSnackbar from "../snackbar/Snackbar";
import backToTop from "reactjs-back-to-top";
import { dataCentral } from "../context/DatagenContext";

const Footer = () => {
  const { activeLink, setActiveLink } = useContext(dataCentral);
  const [incomingMessage, setIncomingMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [captionText, setCaptionText] = useState("");
  const [loading, setLoading] = useState(false);
  const handleActiveLink = (link) => {
    setActiveLink(link);
  };

  const handleBackToTheTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  const handleChange = (event) => {
    const { value, name } = event.target;

    if (name === "email") {
      setEmail(value);
    }
  };

  const handleNewsLetterSubmit = async (e) => {
    e.preventDefault();
    const validation = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const emailTest = validation.test(email);

    if (emailTest) {
      setCaptionText("");
      setLoading(true)
      try {
        
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/api/mail-service/mail3`,
          {
            email: email,
          }
        );
        if (response.status === 200) {
          setOpen(true);
          setIncomingMessage(response.data.message);
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
        if (error.response) {
          setOpen(true);
          setErrorMessage(error.response.data.message);
        } else if (error.request) {
          setOpen(true);
          setErrorMessage(
            "oops..something went wrong, check internet connectivity"
          );
        } else {
          setOpen(true);
          setErrorMessage(
            "oops..something went wrong, check internet connectivity"
          );
        }
      }
    } else {
      setCaptionText("Please input the correct email");
    }
  };

  return (
    <footer>
      <Container>
        <div className={styles.footer_content}>
          <div className={styles.logo_address}>
            <div className={styles.footer_logo}>
              <Link
                onClick={() => {
                  handleBackToTheTop();
                  handleActiveLink("home");
                }}
                to="/"
              >
                <img src={logo} alt="" />
              </Link>
            </div>
            <div className={styles.footer_address}>
              <Typography>B-Datagray</Typography>
              <Typography>
                Castleforbes Road, North Docklands, North Dock,
              </Typography>
              <Typography>
                Dublino, Dublin 1, Leinster, D01 K7E2, Ireland
              </Typography>
              <div className={styles.footer_sm}>
                <a
                  href="https://www.linkedin.com/company/b-datagray"
                  target="_blank"
                >
                  <img src={linkedin_icon} alt="" />
                </a>
                <a href="https://t.me/bdatagray" target="_blank">
                  <img src={telegram_icon} alt="" />
                </a>
                <a href="https://discord.gg/A9EhXheptg" target="_blank">
                  <img src={discord_icon} alt="" />
                </a>
                <a href="https://github.com/Datagen-Project" target="_blank">
                  <img src={github_icon} alt="" />
                </a>
                <a href="https://twitter.com/b_datagray" target="_blank">
                  <img src={twitter_icon} alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className={styles.footer_links_sm}>
            <div class={styles.footer_links}>
              <Link
                className={
                  activeLink === "products" ? styles.active_link : null
                }
                onClick={() => {
                  handleBackToTheTop();
                  handleActiveLink("products");
                }}
                to="/products"
              >
                Products
              </Link>
              <Link
                className={
                  activeLink === "token-sale" ? styles.active_link : null
                }
                onClick={() => {
                  handleBackToTheTop();
                  handleActiveLink("token-sale");
                }}
                to="/token-sale"
              >
                Token sale
              </Link>
              <Link
                className={activeLink === "about" ? styles.active_link : null}
                onClick={() => {
                  handleBackToTheTop();
                  handleActiveLink("about");
                }}
                to="/about"
              >
                About us
              </Link>
              <Link
                className={activeLink === "contact" ? styles.active_link : null}
                onClick={() => {
                  handleBackToTheTop();
                  handleActiveLink("contact");
                }}
                to="/contact"
              >
                Contact us
              </Link>
            </div>
          </div>
          <div className={styles.subscribe}>
            <label htmlFor="">SUBSCRIBE TO OUR NEWSLETTER</label>
            <Typography
              style={{ color: "red" }}
              variant="caption"
              display="block"
            >
              {captionText}
            </Typography>
            <div className={styles.input_box}>
              <input
                onChange={handleChange}
                value={email}
                placeholder="Email"
                type="text"
                name="email"
              />
              <div style={{
               cursor: loading ? 'not-allowed' : 'pointer'
              }} onClick={loading ? null :handleNewsLetterSubmit}>
                {" "}
                {loading ? (
                  <CircularProgress
                    size={20}
                    style={{
                      color: "white",
                    }}
                  />
                ) : (
                  <FaLongArrowAltRight />
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
      <AppSnackbar
        open={open}
        errorMessage={errorMessage}
        incomingMessage={incomingMessage}
        setOpen={setOpen}
      />
    </footer>
  );
};

export default Footer;
