// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Retail_retail__MGS_x {\n    min-height: 100vh;\n     position: relative;\n     background-color: #1c294b;\n     overflow: hidden;\n     padding-bottom: 5rem;\n    \n     \n   }\n   \n   .Retail_retail_underlay__1b00B {\n     position: absolute;\n     top: 0;\n     bottom: 0;\n     right: 0;\n     left: 0;\n   }\n   .Retail_retail_mobile_underlay__39Bsb {\n     display: none;\n   }\n \n   .Retail_retail_content__hUigr {\n     min-height: 100vh;\n       display: flex;\n       flex-direction: column;\n       justify-content: space-between;\n   }", "",{"version":3,"sources":["webpack://src/components/sales/retail/Retail.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;KAChB,kBAAkB;KAClB,yBAAyB;KACzB,gBAAgB;KAChB,oBAAoB;;;GAGtB;;GAEA;KACE,kBAAkB;KAClB,MAAM;KACN,SAAS;KACT,QAAQ;KACR,OAAO;GACT;GACA;KACE,aAAa;GACf;;GAEA;KACE,iBAAiB;OACf,aAAa;OACb,sBAAsB;OACtB,8BAA8B;GAClC","sourcesContent":[".retail {\n    min-height: 100vh;\n     position: relative;\n     background-color: #1c294b;\n     overflow: hidden;\n     padding-bottom: 5rem;\n    \n     \n   }\n   \n   .retail_underlay {\n     position: absolute;\n     top: 0;\n     bottom: 0;\n     right: 0;\n     left: 0;\n   }\n   .retail_mobile_underlay {\n     display: none;\n   }\n \n   .retail_content {\n     min-height: 100vh;\n       display: flex;\n       flex-direction: column;\n       justify-content: space-between;\n   }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"retail": "Retail_retail__MGS_x",
	"retail_underlay": "Retail_retail_underlay__1b00B",
	"retail_mobile_underlay": "Retail_retail_mobile_underlay__39Bsb",
	"retail_content": "Retail_retail_content__hUigr"
};
export default ___CSS_LOADER_EXPORT___;
