// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Airdrop_airdrop__1EDCM {\n   min-height: 100vh;\n    position: relative;\n    background-color: #1c294b;\n    overflow: hidden;\n    padding-bottom: 5rem;\n   \n    \n  }\n  \n  .Airdrop_airdrop_underlay__2g0Ox {\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    right: 0;\n    left: 0;\n  }\n  .Airdrop_airdrop_mobile_underlay__1N2NV {\n    display: none;\n  }\n\n  .Airdrop_airdrop_content__RJfIv {\n    min-height: 100vh;\n      display: flex;\n      flex-direction: column;\n      justify-content: space-between;\n  }\n\n  ", "",{"version":3,"sources":["webpack://src/components/sales/airdrop/Airdrop.module.css"],"names":[],"mappings":"AAAA;GACG,iBAAiB;IAChB,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;IAChB,oBAAoB;;;EAGtB;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,SAAS;IACT,QAAQ;IACR,OAAO;EACT;EACA;IACE,aAAa;EACf;;EAEA;IACE,iBAAiB;MACf,aAAa;MACb,sBAAsB;MACtB,8BAA8B;EAClC","sourcesContent":[".airdrop {\n   min-height: 100vh;\n    position: relative;\n    background-color: #1c294b;\n    overflow: hidden;\n    padding-bottom: 5rem;\n   \n    \n  }\n  \n  .airdrop_underlay {\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    right: 0;\n    left: 0;\n  }\n  .airdrop_mobile_underlay {\n    display: none;\n  }\n\n  .airdrop_content {\n    min-height: 100vh;\n      display: flex;\n      flex-direction: column;\n      justify-content: space-between;\n  }\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"airdrop": "Airdrop_airdrop__1EDCM",
	"airdrop_underlay": "Airdrop_airdrop_underlay__2g0Ox",
	"airdrop_mobile_underlay": "Airdrop_airdrop_mobile_underlay__1N2NV",
	"airdrop_content": "Airdrop_airdrop_content__RJfIv"
};
export default ___CSS_LOADER_EXPORT___;
