import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const InfoSnackbar = ({
  open,
  handleClose,
  getFunction,
  setLoading,
  message,
  successMessage,
}) => {
  const [vertical, setVertical] = useState("top");
  const [horizontal, setHorizontal] = useState("right");

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
       
      </IconButton>
    </React.Fragment>
  );

  return (
    <Snackbar
      action={action}
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="info">
        {message}
      </Alert>
    </Snackbar>
  );
};

export default InfoSnackbar;
