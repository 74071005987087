// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VcSales_vcsale__2uK76 {\n    min-height: 100vh;\n     position: relative;\n     background-color: #1c294b;\n     overflow: hidden;\n     padding-bottom: 5rem;\n    \n     \n   }\n   \n   .VcSales_vcsale_underlay__EEdBn {\n     position: absolute;\n     top: 0;\n     bottom: 0;\n     right: 0;\n     left: 0;\n   }\n   .VcSales_vcsale_mobile_underlay__3TTLB {\n     display: none;\n   }\n \n   .VcSales_vcsale_content__1Xh_y {\n     min-height: 100vh;\n       display: flex;\n       flex-direction: column;\n       justify-content: space-between;\n   }", "",{"version":3,"sources":["webpack://src/components/sales/vc/VcSales.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;KAChB,kBAAkB;KAClB,yBAAyB;KACzB,gBAAgB;KAChB,oBAAoB;;;GAGtB;;GAEA;KACE,kBAAkB;KAClB,MAAM;KACN,SAAS;KACT,QAAQ;KACR,OAAO;GACT;GACA;KACE,aAAa;GACf;;GAEA;KACE,iBAAiB;OACf,aAAa;OACb,sBAAsB;OACtB,8BAA8B;GAClC","sourcesContent":[".vcsale {\n    min-height: 100vh;\n     position: relative;\n     background-color: #1c294b;\n     overflow: hidden;\n     padding-bottom: 5rem;\n    \n     \n   }\n   \n   .vcsale_underlay {\n     position: absolute;\n     top: 0;\n     bottom: 0;\n     right: 0;\n     left: 0;\n   }\n   .vcsale_mobile_underlay {\n     display: none;\n   }\n \n   .vcsale_content {\n     min-height: 100vh;\n       display: flex;\n       flex-direction: column;\n       justify-content: space-between;\n   }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vcsale": "VcSales_vcsale__2uK76",
	"vcsale_underlay": "VcSales_vcsale_underlay__EEdBn",
	"vcsale_mobile_underlay": "VcSales_vcsale_mobile_underlay__3TTLB",
	"vcsale_content": "VcSales_vcsale_content__1Xh_y"
};
export default ___CSS_LOADER_EXPORT___;
